import React, { useState } from "react";

import { Button, Grid } from "@material-ui/core";

import { Auth } from "aws-amplify";
import { Formik } from "formik";
import InputField from "../components/InputField";

import { ForgotPasswordSchema } from "../utils/validation";
import { navigate } from "gatsby";
import FormColumn from "src/components/FormColumn";
import Error from "src/components/Error";
const ForgotPassword = () => {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);

  async function handleSendCodeClick(event, values) {
    event.preventDefault();
    values.username = values.username.toLowerCase();
    try {
      await Auth.forgotPassword(values.username);
      setStep(step + 1);
      setError(null);
    } catch (error) {
      console.log(error);

      setError(error.message);
    }
  }

  async function handleConfirmClick(event, values) {
    event.preventDefault();
    values.username = values.username.toLowerCase();
    try {
      await Auth.forgotPasswordSubmit(
        values.username,
        values.code,
        values.password
      );
      navigate("/login");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  }

  return (
    <Formik
      initialValues={{
        code: "",
        username: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={ForgotPasswordSchema}
    >
      {({ values }) => (
        <>
          {step === 0 && (
            <form onSubmit={(event) => handleSendCodeClick(event, values)}>
              <FormColumn>
                <InputField name="username" label="Username" />
                <Grid item>
                  {" "}
                  <Button type="submit">Send code</Button>
                </Grid>
              </FormColumn>
            </form>
          )}
          {step === 1 && (
            <form onSubmit={(event) => handleConfirmClick(event, values)}>
              <FormColumn>
                <InputField name="code" label="Code" />
                <InputField type="password" name="password" label="Password" />
                <InputField
                  type="password"
                  name="confirmPassword"
                  label="Confirm password"
                />
                <Grid item>
                  <Button type="submit">Change password</Button>
                </Grid>
              </FormColumn>
            </form>
          )}
          {error && <Error error={error} />}
        </>
      )}
    </Formik>
  );
};
export default ForgotPassword;
