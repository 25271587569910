//https://github.com/wesbos/dump
import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Grid, Box } from "@material-ui/core";

const Error = ({ error }) => (
  <Grid item xs={6} sm={3}>
    <Box mt={2}>
      <Alert variant="outlined" severity="error">
        {error}
      </Alert>
    </Box>
  </Grid>
);

export default Error;
